import "reactjs-popup/dist/index.css";
import mobile from "is-mobile";
import Popup from "reactjs-popup";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import Input from "./Input";
import useFetch from "./useFetch";
import { toastError } from "./toast";
import Toggle from "./Toggle";

const getDefaultDate = () => {
  const today = new Date();
  today.setMonth(today.getMonth() + 3);
  return today.toISOString().substring(0, 10);
};

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};
const CreateEventPopupButton = ({ me }) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [eventDate, setEventDate] = useState(getDefaultDate());
  const [isDemoLoading, setIsDemoLoading] = useState(false);
  const [shouldUseEventName, setShouldUseEventName] = useState(true);

  const { fetchData, error, loading } = useFetch({
    method: "post",
    onSuccess: ({ eventId }) => {
      navigate({
        pathname: `/event`,
        search: `eventId=${eventId}`,
      });
    },
  });

  const onCreateEventClick = (e, isDemo) => {
    e.preventDefault();
    if (!name) {
      toastError("יש למלא את שם האירוע");
      return;
    }

    if (!isValidDate(new Date(eventDate))) {
      toastError("תאריך האירוע אינו תקין");
      return;
    }

    if (!isDemo) {
      if (!me?.eventTokens || me?.eventTokens <= 0) {
        toastError(
          "אין לך אירועים לכותתך. אנא פנה לתמיכה לקבלת אירועים חדשים!"
        );
        return;
      }
    }

    if (isDemo) {
      setIsDemoLoading(true);
    }

    fetchData({
      query: "createEvent",
      params: {
        name,
        eventDate,
        isDemo,
        shouldUseEventName,
      },
    });
  };
  return (
    <Popup
      className="small-popup"
      trigger={
        <button className="primary-button d-flex align-items-center create-event-button">
          {mobile() ? null : <img src="/plus.svg" alt="plus" />}
          <span className="pr12">יצירת אירוע חדש</span>
        </button>
      }
      modal
    >
      <form className="d-flex-column align-items-center font-l">
        <img className="logo-icon mb80" src="/picme-logo.svg" alt="logo" />
        <Input
          className={"mb40"}
          type="date"
          label="תאריך האירוע"
          value={eventDate}
          onChange={(e) => {
            setEventDate(e.target.value);
          }}
        />
        <Input
          autoFocus
          label="שם בעל האירוע \ שם הזוג"
          placeholder="האירוע של..."
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <div className="mb40 d-flex justify-content-start full-width">
          <Toggle
            toggleId={"shouldUseEventName"}
            className={"d-flex align-items-center disable-select"}
            isChecked={shouldUseEventName}
            onClick={() => setShouldUseEventName(!shouldUseEventName)}
            customLabelContent={
              <div className="pr12  thin">
                {shouldUseEventName
                  ? "שם האירוע יוצג לאורחים"
                  : "שם האירוע יוסתר מהאורחים"}
              </div>
            }
          />
        </div>

        <div className="form-error">{error}</div>

        <div className="full-width">
          <button
            className="p16 mb24 d-flex align-items-center justify-content-center full-width"
            onClick={onCreateEventClick}
            disabled={!name}
          >
            <span>יצירת אירוע</span>
            {loading && !isDemoLoading && (
              <span className="mr12 loading-circle"></span>
            )}
          </button>
          <div className="d-flex-column align-items-center">
            <div className="center">
              <span>{`לזכותך ${me?.eventTokens || 0} אירועים.`}</span>
              <span className="thin pr-text">
                ביצירת אירוע הוא יירד מסך האירועים לזכותך.
              </span>
            </div>

            {/* <div> */}
            {/*   <CheckmarkRow text={"סלפים ללא ללא הגבלה"} /> */}
            {/*   <CheckmarkRow text={"עד 3000 תמונות באלבום"} /> */}
            {/*   <CheckmarkRow text={"ייסגר באופן אוטומטי לאחר 3 שבועות"} /> */}
            {/* </div> */}
          </div>
        </div>
        {/* <div className="mt24"> */}
        {/*   <button */}
        {/*     className="d-flex align-items-center justify-content-center  full-width mb12" */}
        {/*     onClick={(e) => onCreateEventClick(e, true)} */}
        {/*     // disabled={!name} */}
        {/*   > */}
        {/*     <span>יצירת אירוע דמו</span> */}
        {/*     {loading && isDemoLoading && ( */}
        {/*       <span className="mr12 loading-circle"></span> */}
        {/*     )} */}
        {/*   </button> */}
        {/*   <div className="d-flex-column align-items-center"> */}
        {/*     <div className="bold pb8"> */}
        {/*       יצירת אירועי דמו הינה בחינם וללא הגבלה! */}
        {/*     </div> */}
        {/*     <div> */}
        {/*       <CheckmarkRow text={"עד 5 סלפים"} /> */}
        {/*       <CheckmarkRow text={"עד 100 תמונות באלבום"} /> */}
        {/*       <CheckmarkRow text={"ייסגר באופן אוטומטי לאחר 24 שעות"} /> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}
      </form>
    </Popup>
  );
};

const CheckmarkRow = ({ text }) => (
  <div className="mt12 d-flex align-items-center">
    <StarIcon />
    <span>{text}</span>
  </div>
);

const InfoIcon = ({ className }) => (
  <img
    className={`icon-small ${className || ""}`}
    alt={"info"}
    src={"./info.svg"}
  />
);
const StarIcon = () => (
  <img className="pl12 icon-small" alt={"star"} src={"./star.svg"} />
);
export default CreateEventPopupButton;
