import React, { useCallback, useEffect, useState } from "react";
import mobile from "is-mobile";
import * as XLSX from "xlsx/xlsx.mjs";
import parsePhoneNumber from "libphonenumber-js";

import { toastSuccess, toastError } from "./toast";
import useFetch from "./useFetch";
import { useDropzone } from "react-dropzone";
import "./UploadImagesWidget.css";
import Toggle from "./Toggle";
import { i18n } from "./i18n";
import "./BroadcastEvent.css";

const MAX_FILE_SIZE = 1024 * 1024 * 30;
const MAX_BROADCASTS_PER_EVENT = 600;

const BroadcastEvent = ({ event }) => {
  const [phones, setPhones] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isShareBeforeEvent, setIsShareBeforeEvent] = useState(true);

  const {
    fetchData,
    error,
    // data,
    loading: sending,
  } = useFetch({
    method: "post",
    onSuccess: (resJson) => {
      const { failedCount, sentCount } = resJson;
      console.log({ failedCount, sentCount });
      setPhones(false);
      if (failedCount === 0) {
        toastSuccess(
          "הודעת תפוצה נשלחה בהצלחה ל-" +
            sentCount +
            " אורחים! האורחים יקבלו הודעה עם לינק לאירוע זה בדקות הקרובות."
        );
      } else {
        toastError(
          `קרתה שגיאה לא צפויה. מתוך
           ${failedCount + sentCount} הודעות
             השליחה נכשלה עבור ${failedCount} הודעות `,
          { disableAutoClose: true }
        );
      }
    },
  });

  const onDrop = useCallback(async (files) => {
    if (files.find((file) => file.size > MAX_FILE_SIZE)) {
      toastError("לא ניתן להעלות קוסץ בגודל שעולה על 30MB");
      return;
    }

    setLoading(true);

    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        const validPhones = jsonData
          .map((row) => {
            const cellValues = Object.values(row);
            for (let i = 0; i <= 10; i++) {
              if (cellValues[i]) {
                const phoneNumber = parsePhoneNumber(
                  cellValues[i].toString(),
                  "IL"
                );
                if (phoneNumber && phoneNumber.isValid()) {
                  return (
                    phoneNumber.countryCallingCode + phoneNumber.nationalNumber
                  );
                }
              }
            }
            return null;
          })
          .filter(Boolean);

        setPhones([...new Set(validPhones)]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toastError("אופס... קרתה שגיאה לא צפוייה");
        throw new Error(e);
      }
    };

    reader.onerror = (errorEvent) => {
      setLoading(false);
      toastError("שגיאה בקריאת הקובץ");
      console.error("FileReader Error:", errorEvent?.target?.error);
      throw new Error(errorEvent);
    };

    reader.readAsArrayBuffer(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: {
    // "application/vnd.ms-excel": [".xlsx", "xls"],
    // },
  });

  const handleBroadcastEvent = () => {
    if (sending) {
      return;
    }
    if (!!phones?.length) {
      fetchData({
        method: "POST",
        query: "broadcastEvent",
        params: {
          phones,
          eventId: event._id,
          isShareBeforeEvent,
        },
      });
    }
  };

  return (
    <>
      <div className="pl24">
        <div
          className={`purple-dark-text bold font-xl ${mobile() ? "pt24" : ""}`}
        >
          {"שלחו הודעת תפוצה לרשימת אורחים"}
        </div>
        <div className="pt24 font-l">הודעת התפוצה מכילה את שם האירוע</div>
        <div className="pt12 font-l regular">
          יש להעלות קובץ אקסל שהעמודה הראשונה בו מכילה מספרי טלפון לשיתוף
          האירוע.
        </div>

        <div className="pt12 font-l">סיומות קובץ נתמכות: xls. xlsx.</div>
        <div className="pt12 font-l">
          <a
            className="purple-text"
            target="_blank"
            rel="noopener noreferrer"
            href={
              "https://docs.google.com/spreadsheets/d/1-x6A1yeQln6y171K74v4tCQaWp0kR42UImrz6qvqk5M"
            }
          >
            קובץ לדוגמא
          </a>
        </div>
        <div className="bt mt12 pt12 font-l">{`ניתן לשתף את האירוע עם ${
          (event.broadcastSentCount || 0) === 0
            ? "עד 500"
            : `עוד ${MAX_BROADCASTS_PER_EVENT - event.broadcastSentCount}`
        } אורחים`}</div>

        {error === "error-max-broadcasts-per-event" ? (
          <div className="pt24 font-l red-text">
            ניתן לשתף את האירוע עם עד 500 אורחים
          </div>
        ) : null}

        {error === "no-broadcast-tokens" ? (
          <div>
            <div className="pt24 font-l red-text">
              אירוע זה לא כולל הודעת תפוצה
            </div>
            <button
              className="mt12 full-width"
              onClick={() => toastSuccess("נשמח לשדרג! אנא צרו קשר עם התמיכה.")}
            >
              שדרג לאירוע הכולל הודעת תפוצה
            </button>
          </div>
        ) : !!phones?.length ? (
          <>
            <Toggle
              toggleId="broadcastBeforeToggle"
              className={
                "mt24 d-flex-mobile align-items-center EventSettings-AutoSent disable-select"
              }
              isChecked={isShareBeforeEvent}
              onClick={() => setIsShareBeforeEvent(!isShareBeforeEvent)}
              customLabelContent={
                <div
                  className={`d-flex align-items-center thin ${
                    mobile() ? "pt12" : "pr12"
                  }`}
                >
                  <div className={`font-l purple-text`}>
                    {isShareBeforeEvent
                      ? "הודעה המותאמת לשליחה לפני האירוע"
                      : "הודעה המותאמת לשליחה לאחר האירוע"}
                  </div>
                </div>
              }
            />

            <button
              disabled={sending}
              className="mt24 full-width"
              onClick={handleBroadcastEvent}
            >
              {sending ? (
                <span>{`שולח הזמנה לוואטסאפ ל ${phones.length} אורחים`}</span>
              ) : (
                <span>{`שלח הזמנה לוואטסאפ ל ${phones.length} אורחים`}</span>
              )}
              {sending && <span className="mh24 loading-circle"></span>}
            </button>
          </>
        ) : null}
        <BroadcastEventSentStatus event={event} />
      </div>
      <div className="UploadImagesWidget" {...getRootProps()}>
        <input
          type="file"
          accept="application/vnd.ms-excel"
          {...getInputProps()}
        />
        <div className="font-l d-flex align-items-center justify-content-center">
          {loading ? (
            <div className="d-flex-column">
              <div className="d-flex align-items-center mb24">
                <span className="pl12">הקובץ בטעינה</span>
              </div>
            </div>
          ) : isDragActive ? (
            <div>שחרר את הקובץ כאן</div>
          ) : !!phones ? (
            <div className="center">
              <div className="pb12">
                <span className="pl-text">נמצאו</span>
                <span className="bold">{phones.length}</span>
              </div>
              <div>
                <span>מספרי טלפון תקינים</span>
              </div>
            </div>
          ) : (
            <div className="d-flex-column justify-content-center align-items-center">
              <div className="pb32">
                <img src="/upload.svg" alt="upload" />
              </div>
              <div className="pb24">גרור לכאן רשימת אורחים</div>
              <div className="">או לחץ להעלאה</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const BroadcastEventSentStatus = ({ event }) => {
  const { fetchData: getBroadcastEventSentStatus, data } = useFetch();

  useEffect(() => {
    getBroadcastEventSentStatus({
      query: "getBroadcastEventSentStatus",
      params: {
        eventId: event._id,
      },
    });
  }, []);

  if (!data?.broadcastEventSentStatus?.length) {
    return null;
  }

  return (
    <div className="BroadcastEventStatus mt24">
      {Object.entries(data?.broadcastEventSentStatusCounts).map(
        ([status, count]) =>
          count > 0 ? (
            <span key={status} className="BroadcastEventStatusItem">
              {count} {i18n(`wa_${status}`)}
            </span>
          ) : null
      )}
    </div>
  );
};

export default BroadcastEvent;
