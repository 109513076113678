import React, { useState, useEffect, useMemo } from "react";
import { List, AutoSizer } from "react-virtualized";
import mobile from "is-mobile";
import copy from "copy-to-clipboard";

import useFetch from "./useFetch";
import { LOGIN_TOKEN_KEY, USER_PHONE, USER_ROLE } from "./Login";
import { Tooltip } from "react-tooltip";
import LoadingSection from "./LoadingSection";
import StarIcon from "./StarIcon";
import { toastSuccess } from "./toast";

const SEND_TOP_IMAGES = 1000;
const SHOW_TOP_IMAGES = 98;

const ROW_HEIGHT = 440; //--image-height + --image-box-margin-bottom + --image-box-footer-height
const IMAGES_PER_ROW = 6;

const ADMIN_DEVELOPER_PHONES = ["972545874925"];
const isAdminDeveloper = () => {
  const userPhone = localStorage.getItem(USER_PHONE);
  return ADMIN_DEVELOPER_PHONES.includes(userPhone);
};

const getImagesPerRow = () => {
  return mobile() ? 1 : IMAGES_PER_ROW;
};

function toBase64(arr) {
  return arr
    ? btoa(arr.reduce((data, byte) => data + String.fromCharCode(byte), ""))
    : null;
}

const SearchResults = ({ eventId }) => {
  const [selfies, setSelfies] = useState();

  const { fetchData, data, loading } = useFetch();
  useEffect(() => {
    fetchData({
      query: "getSelfies",
      params: {
        eventId,
      },
    });
  }, []);

  useEffect(() => {
    if (data?.selfies) {
      setSelfies(
        data.selfies.filter((s) => isAdminDeveloper() || !!s?.matches?.length)
      );
    }
  }, [data]);

  const cleanAllMatches = (selfieId) => {
    const isConfirmed = window.confirm(
      "האם אתה בטוח שברצונך להסיר את כל התמונות של אורח זה (תמונות שיוסרו לא יישלחו)?"
    );

    if (!isConfirmed) {
      return;
    }

    // Optimistic response
    setSelfies(
      selfies.map((selfie) => ({
        ...selfie,
        ...(selfie._id === selfieId && {
          matches:
            selfie.matches?.map((match) => ({
              ...match,
              isRemoved: true,
              isAdded: false,
            })) || [],
        }),
      }))
    );

    fetch(
      `${process.env.REACT_APP_SERVER_ENDPOINT}/cleanAllMatches?selfieId=${selfieId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(LOGIN_TOKEN_KEY)}`,
        },
      }
    )
      .then(async (res) => {})
      .catch((err) => console.log("CLIENT error", err))
      .finally(() => {});
  };

  const cleanWrongMatch = (selfieId, albumImageKey, remove) => {
    // Optimistic response
    setSelfies(
      selfies.map((selfie) =>
        selfie._id === selfieId
          ? {
              ...selfie,
              matches:
                selfie.matches?.map((match) =>
                  match.albumImage.key === albumImageKey
                    ? { ...match, isRemoved: remove, isAdded: !remove }
                    : match
                ) || [],
            }
          : selfie
      )
    );

    fetch(
      `${
        process.env.REACT_APP_SERVER_ENDPOINT
      }/cleanWrongMatch?selfieId=${selfieId}&albumImageKey=${albumImageKey}&revert=${!remove}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(LOGIN_TOKEN_KEY)}`,
        },
      }
    )
      .then(async (res) => {})
      .catch((err) => console.log("CLIENT error", err))
      .finally(() => {});
  };

  const getSelfieRowHeight = ({ index }) => {
    const selfie = selfies[index];

    const selfieMatchesCount = Math.min(
      selfie.matches?.length || 0,
      SHOW_TOP_IMAGES
    );

    const imagesPerRow = getImagesPerRow();
    const rowHeight = Math.max(
      Math.ceil((selfieMatchesCount + 1) / imagesPerRow) * ROW_HEIGHT + 20,
      ROW_HEIGHT
    );

    return rowHeight;
  };

  const selfieRowRenderer = ({ index, key, style }) => {
    const selfie = selfies[index];

    const rowHeight = getSelfieRowHeight({ index });
    return (
      <div key={key} style={{ ...style, height: rowHeight }}>
        <SelfieResult
          key={selfie._id}
          selfie={selfie}
          cleanWrongMatch={cleanWrongMatch}
          cleanAllMatches={cleanAllMatches}
        />
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingSection />
      ) : (
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                className="SearchResults"
                width={width} // Width of your list based on the parent container
                height={mobile() ? 1000 : height} // Height of your list
                rowCount={selfies?.length || 0}
                rowHeight={getSelfieRowHeight} // Pass the function to calculate the dynamic height
                rowRenderer={selfieRowRenderer}
              />
            );
          }}
        </AutoSizer>
      )}
    </>
  );
};

export const SelfieResult = ({ selfie, cleanWrongMatch, cleanAllMatches }) => {
  if (!selfie) {
    console.log("return null");
    return null;
  }

  const matchesRows = selfie.matches
    ?.sort((a, b) =>
      a.distance === b.distance ? 0 : a.distance < b.distance ? -1 : 1
    )
    .slice(0, SHOW_TOP_IMAGES)
    .reduce((rows, match, index) => {
      const rowIndex = Math.floor(
        (index + mobile() ? 0 : 1) / getImagesPerRow()
      );

      if (!rows[rowIndex]) rows[rowIndex] = [];

      rows[rowIndex].push(match);

      return rows;
    }, []);

  return (
    <>
      {mobile() ? (
        <div key={selfie._id + "the selfie"} className="SearchResults-row">
          <SelfieBox selfie={selfie} cleanAllMatches={cleanAllMatches} />
        </div>
      ) : null}
      {matchesRows?.length ? (
        matchesRows.map((row, rowIndex) =>
          mobile() ? (
            row.map((match, matchIndex) => (
              <div
                key={selfie._id + rowIndex + matchIndex}
                className="SearchResults-row"
              >
                <MatchBox
                  key={selfie._id + match.albumImage.key}
                  index={rowIndex * getImagesPerRow() + matchIndex}
                  match={match}
                  selfie={selfie}
                  cleanWrongMatch={cleanWrongMatch}
                />
              </div>
            ))
          ) : (
            <div key={selfie._id + rowIndex} className="SearchResults-row">
              {rowIndex === 0 ? (
                <SelfieBox selfie={selfie} cleanAllMatches={cleanAllMatches} />
              ) : null}
              {row.map((match, matchIndex) => (
                <MatchBox
                  key={selfie._id + match.albumImage.key}
                  index={rowIndex * getImagesPerRow() + matchIndex}
                  match={match}
                  selfie={selfie}
                  cleanWrongMatch={cleanWrongMatch}
                />
              ))}
              <ShowMoreButton
                selfieId={selfie.id}
                showMoreCount={selfie.matches.length - SHOW_TOP_IMAGES}
              />
            </div>
          )
        )
      ) : (
        <div key={selfie._id} className="SearchResults-row">
          <SelfieBox selfie={selfie} cleanAllMatches={cleanAllMatches} />
        </div>
      )}
    </>
  );
};

const getMessageStatus = ({
  messageId = "not_sent_yet",
  isSent,
  statusArray,
  shouldSend,
}) => {
  let statusClass = shouldSend ? "pending" : "removed";
  let moreText;
  let statusText = shouldSend ? "ממתין לשליחה" : "הוסר משליחה";
  if (isSent) {
    statusText = "התמונה נשלחה";
    statusClass = "sent";
  }

  const failedStatus = statusArray?.find(({ status }) => status === "failed");
  if (failedStatus) {
    console.log(messageId + ":" + JSON.stringify(failedStatus));
    statusText = "השליחה נכשלה";
    statusClass = "error";
    moreText = failedStatus.errors
      ?.map(({ title, code }, index) => title)
      .join();
  } else {
    if (statusArray?.find(({ status }) => status === "sent")) {
      statusText = "נשלחה";
      statusClass = "sent";
    }
  }

  if (statusArray?.find(({ status }) => status === "delivered")) {
    statusClass = "sent";
    statusText = "נשלחה בהצלחה";
  }
  if (statusArray?.find(({ status }) => status === "read")) {
    statusClass = "seen";
    statusText = "התמונה נראתה";
    // moreText = "האורח ראה את התמונה!";
  }

  return {
    statusClass,
    statusText,
    moreText,
  };
};
export const SentStatusIndicator = ({
  _key,
  messageId = "not_sent_yet",
  isSent,
  statusArray,
  shouldSend,
  isFromMyPics,
}) => {
  const { statusClass, statusText, moreText } = getMessageStatus({
    messageId,
    isSent,
    statusArray,
    shouldSend,
  });
  const tooltipId = _key + "_" + messageId;
  if (!isFromMyPics && statusClass !== "error") {
    return null;
  }
  return (
    <>
      {moreText ? (
        <Tooltip className="small-tooltip" id={tooltipId}>
          {moreText}
        </Tooltip>
      ) : null}

      <div
        data-tooltip-id={tooltipId}
        className={`SearchResultItem-actions-status SearchResultItem-actions-status-${statusClass}`}
      >
        {statusText}
      </div>
    </>
  );
};

const ImageWithPlaceHolder = ({ className, alt, src, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);

  function onLoad() {
    // delay for demo only
    // setTimeout(() => setIsLoading(false), 1000);

    setIsLoading(false);
  }

  return (
    <>
      <img
        className={className}
        alt={alt}
        src="/picme-logo.svg"
        style={{ display: isLoading ? "block" : "none" }}
      />
      <img
        onClick={onClick}
        className={className}
        alt={alt}
        src={src}
        style={{ display: isLoading ? "none" : "block" }}
        onLoad={onLoad}
      />
    </>
  );
};

export const SelfieBox = ({ selfie, cleanAllMatches }) => {
  const { _id, s3Url, phone } = selfie;
  const tooltipId = selfie._id.toString() + "_matchesLinkStatus";
  const matchesLinkStatus = getMessageStatus({
    statusArray: selfie.matchesLinkSentStatus,
  });

  const { fetchData: getSelfieToken, loading } = useFetch({
    onSuccess: (resJson) => {
      copy(
        `${process.env.REACT_APP_CLIENT_ENDPOINT}/my-pics?token=${resJson.selfieToken}`
      );
      toastSuccess("לינק של הסלפי הועתק בהצלחה");
    },
  });
  const copyLink = () => {
    getSelfieToken({
      query: "getSelfieToken",
      params: { selfieId: selfie._id },
    });
  };

  const userRole = useMemo(() => localStorage.getItem(USER_ROLE), []);

  return (
    <div className="SearchResultItem-selfie">
      <img
        className="SearchResultItem-selfie-image"
        src={s3Url ? s3Url : null}
        alt={phone}
      />
      <div className="full-width purple-dark-text">
        <div className="center pb12 ltr">
          {userRole === "super_admin"
            ? selfie.phone
            : selfie.phone.replace(selfie.phone.slice(0, 8), "******")}
        </div>
        <div className="ph12">
          <div className="divider"></div>
        </div>
        <div className="SearchResultItem-footer SearchResultItem-footer-selfie">
          <div className="SearchResultItem-footer-action">
            {selfie.isMatchesLinkSent ? (
              <div className="d-flex align-items-center justify-content-center">
                {matchesLinkStatus.statusClass === "error" ? (
                  <>
                    <Tooltip className="small-tooltip" id={tooltipId}>
                      {matchesLinkStatus.moreText}
                    </Tooltip>
                    <div
                      className="red-text pl-text"
                      data-tooltip-id={tooltipId}
                    >
                      X לא
                    </div>
                  </>
                ) : (
                  <div onClick={copyLink}>
                    <StarIcon />
                  </div>
                )}
                <div>
                  {selfie.lastSeenImagesLink ? (
                    <span>האורח ראה את התמונות</span>
                  ) : (
                    <span className="pl-text">נשלח לינק עם התמונות</span>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="pl12">{`${
                  selfie.matches?.length || 0
                } תמונות נמצאו`}</div>
                {cleanAllMatches ? (
                  <div
                    className="red-text pointer"
                    onClick={() => cleanAllMatches(_id)}
                  >{`הסר הכל`}</div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const shouldSendMatch = ({ isRemoved, isAdded, index }) => {
  return (!isRemoved && index < SEND_TOP_IMAGES) || isAdded;
};
const MatchBox = ({ index, match, selfie, cleanWrongMatch }) => {
  const {
    status,
    isRemoved,
    distance,
    albumImage,
    isSent,
    messageId,
    isAdded,
  } = match;

  const shouldSend = shouldSendMatch({ isRemoved, index, isAdded });
  return (
    <div className="SearchResultItem-imageBox">
      <div
        className={`SearchResultItem-image-wrapper ${
          shouldSend ? "" : "SearchResultItem-image-wrapper-isRemoved"
        }`}
        key={index + "_" + albumImage.key}
      >
        {
          <SentStatusIndicator
            _key={index + "_" + albumImage.key}
            isSent={isSent}
            statusArray={status}
            messageId={messageId}
            shouldSend={shouldSend}
          />
        }

        <ImageWithPlaceHolder
          onClick={() => {
            cleanWrongMatch(selfie._id, albumImage.key, shouldSend);
          }}
          className="SearchResultItem-image"
          key={albumImage.key + "_" + index}
          alt={albumImage.url}
          src={albumImage.url}
        />
      </div>
      <div className="SearchResultItem-footer">
        <div className="SearchResultItem-actions-remove-wrapper">
          <div className="SearchResultItem-actions-score">
            <span>{100 - Math.trunc((distance || 0) * 100)}%</span>
            <span className="">התאמה</span>
          </div>

          {shouldSend ? (
            <div
              className="SearchResultItem-actions-remove"
              onClick={() => cleanWrongMatch(selfie._id, albumImage.key, true)}
            >
              הסר
            </div>
          ) : (
            <div
              className="SearchResultItem-actions-revert"
              onClick={() => cleanWrongMatch(selfie._id, albumImage.key, false)}
            >
              סמן
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ShowMoreButton = ({ selfieId, showMoreCount }) => {
  if (showMoreCount < 1) {
    return null;
  }

  return (
    <div key={selfieId + "_show_all"} className="d-flex align-items-end">
      <button className="inline-button">
        <span className="pl-text">ועוד</span>
        <span>{showMoreCount}</span>
        <span>...</span>
      </button>
    </div>
  );
};

export default SearchResults;
